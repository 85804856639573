import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private url = environment.admin;
  private restartMediaEvent = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  async list() {
    return this.http.get<any[]>(`${this.url}/product/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  async listBy(option,value){

    let key = ''; 
    let param = '';

    if(option == 'search'){
      key = 'searchQuery';
      param = 'listBySearchQuery';
    }

    if(option == 'category'){
      key = 'category';
      param = 'listByCategory';
    }

    if(option == 'subCategory'){
      key = 'subCategory',
      param = 'listBySubCategory';
    }

    return this.http.get<any[]>(`${this.url}/product/${param}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(key ,value)
    });
  }

  getOne(id){
    return this.http.get<any[]>(`${this.url}/product/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  getMediaRestartEvent(){
    return this.restartMediaEvent;
  }
  
  restartMedia(){
    this.restartMediaEvent.emit(true);
  }
};
