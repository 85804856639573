export const environment = {
    production: false,
    marketKey : 'globbboo',
    admin: 'https://api-admin.globboo.cl',
    checkout : 'https://checkout.globboo.cl',
    cartApi: 'https://api-cart-redis.globboo.cl',
    gtag : "GK2ZXXKXW2",
    enterpriseId:'65a9be3af961c35186073a3b',
    captchaWebKey: '6LcPCewUAAAAAHbnPPclBXV3MwGUMAfNZ5qKYuXI',
  };
