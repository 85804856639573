import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  private url = environment.admin;
  
  constructor(private http: HttpClient) {
  }

  async list() {
    return this.http.get<any[]>(`${this.url}/categories/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  async listFullCategory(){
    return this.http.get<any[]>(`${this.url}/categories/fullCategories` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  getOneByAlias(alias){
    return this.http.get<any[]>(`${this.url}/category/getByAlias/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('categoryalias', alias)
    });
  }
};
