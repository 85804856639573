import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '../../_services/tracking.service';

@Component({
  selector: 'app-store-tracking',
  templateUrl: './store-tracking.page.html',
  styleUrls: ['./store-tracking.page.scss'],
})
export class StoreTrackingPage implements OnInit {

  constructor(private router: Router, private trackingService: TrackingService) { }

  ngOnInit() {
  }
  trackingCode : string = '';

  setCode(){
    this.trackingService.setCode(this.trackingCode);
    this.routerToTracking();
  }

  routerToTracking(){
    this.router.navigate(['/tracking']);
  }
  
}
