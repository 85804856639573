import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.page.html',
  styleUrls: ['./store-list.page.scss'],
})
export class StoreListPage implements OnInit {

  @Input() data=[];
  @Input() props = {} ;

  constructor() { }

  ngOnInit() {
  }

}
