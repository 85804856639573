import { Injectable } from '@angular/core';
import { DataStorageService } from './dataStorage.service';
import { EventsService } from 'src/app/_services/events.service';

interface auth {
  accessToken : string;
  refreshToken : string;
  client : Object;
}

@Injectable()

export class SessionService {
  private token : string = "";
  private client;
  private isLogged = false;

  constructor(private dataStorage: DataStorageService, private eventsService: EventsService) { 
    this.init()
  }

  async init(){
    const val: auth = await this.dataStorage.get('client');
    if(val){
      this.setClient(val);
      this.setToken(val.accessToken);
      this.eventsService.addEventEmitter('isLogged');
      this.eventsService.emitEventValue('isLogged', true);
    }
  }

  async createSession(session){
    session.client.accessToken = session.accessToken; 
    await this.dataStorage.set('client',session.client);
    this.setClient(session.client);
    this.setToken(session.accessToken);
    this.setLoggedStatus(true);
    this.eventsService.emitEventValue('isLogged', true);
  }

  private setLoggedStatus(status){
    this.isLogged = status;
  }

  private getLoggedStatus(){
    return this.isLogged;
  }

  private setToken(token){
    console.log('a:', token);
    this.token = token;
    console.log('111111:', this.token);
  }

  getToken(){
    return this.token;
  }

  private setClient(client){
    this.client = client;
  }

}