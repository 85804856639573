import { Component, OnInit , Input} from '@angular/core';
import { Router } from '@angular/router';
import { ComponentRedirect } from 'src/app/_services/componentRedirect.service';

interface dataGridData{
  id: string;
  imgUrl: string;
  title ?: string;
  name ?: string;
  type: string;
  value: string;
}

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.page.html',
  styleUrls: ['./card-grid.page.scss'],
})
export class CardGridPage implements OnInit {
  @Input() data : dataGridData[] = [];
  @Input() props = {} ;
  @Input() minColumns=2;
  @Input() maxColumns=6;
  textMaxValue= 25;

  constructor( private router: Router, private redirectToPage: ComponentRedirect) {
  }

  ngOnInit() {
    console.log('data card grid',this.data);

  }

  changeImage(index){
    //console.log(index);
    this.data[index]['statusImg']=true;
  }
  restoreImage(index){
    //console.log(index);
    this.data[index]['statusImg']=false;
  }
/*
  slicedCategories(arr){
    return arr.slice(1,this.productQuantity+1);
  }*/

  public getStyles() {
    if(!this.props['columns'].value){
      this.props['columns'].value=this.minColumns;
    }
    if(this.data.length<this.props['columns'].value){
      this.props['columns'].value=this.data.length;
    }
    if(this.props['columns'].value>this.maxColumns){
      this.props['columns'].value=this.maxColumns;
    }
    return {
      'grid-template-columns': `repeat(${this.props['columns'].value}, 1fr)`,
    };
    
  }

  navigateItem(item){
    this.redirectToPage.navigateItem(item)
  }

  maxSizeString(text,size){
    if(text){
      return text.slice(0,size);
    }else{
      return '';
    }      
  }


}
