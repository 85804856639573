import { Component, Input, OnInit } from '@angular/core';
import { ComponentRedirect } from 'src/app/_services/componentRedirect.service';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.page.html',
  styleUrls: ['./simple-list.page.scss'],
})
export class SimpleListPage implements OnInit {
  @Input() data=[];
  @Input() props = {} ;
 
  firstElements:number = 10
  activeItems:Array<any> = []
  inactiveItems:Array<any> = []

  constructor(private redirectToPage: ComponentRedirect) { }

  ngOnInit() {
    this.inactiveItems= this.data;
    this.setElements()
  }

  ngAfterViewInit(){

  }

  navigateItem(item){

    this.redirectToPage.navigateItem(item)
  }
  left(){

  }
  right(){
  }


  setElements(){

    for(let i=0;i<this.firstElements; i++){

      this.activeItems.push(this.inactiveItems[i])

    }
    
    /*
    const items = document.querySelectorAll('.simple-list-item');
    for (const item of items) {
      item.classList.add('active');
    }*/


    
  }

}
