import { Component, Input, OnInit } from '@angular/core';
import { ComponentRedirect } from 'src/app/_services/componentRedirect.service';

  interface Slide{
    name: string,
    imgUrl: string,
    alias : string,
    id: string,
    type: string,
    value: string,
  }

  interface propsList{
    title: prop;
    sectionBackgroundColor: prop
    sectionTitleColor : prop
    sectionTitleDecoration: prop
    intervalTime:prop
  }

  interface prop{
    type:string
    value:string
  }



@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

  loading: boolean = false;
  sliderInterval;
  timer: number;
  defaultTime: number = 3000;

  currentSlide: Slide;
  currentSlideIndex: number = 0;
  @Input() data :  Slide[] = [
    {
      name:
      "Adornos y decoración para el hogar",
        imgUrl:
      "https://cdn.discordapp.com/attachments/695763626572775454/1093595930965581954/gifGLOBBOO.gif",
        alias:
      "adornos",
        id:
      "6387642c0913d4ac30a3db2f",
        type:
      "Search",
        value:
      "",
    },
  ];
  @Input() props: propsList = {
    title: {
      type: "string",
      value: "Homme 1"
    },
    sectionBackgroundColor: {
      type: "colorCode",
      value: "white"
    },
    sectionTitleColor: {
      type: "colorCode",
      value: "black"
    },
    sectionTitleDecoration: {
    type: "string",
      value: "none"
    },
    intervalTime: {
      type: "number",
      value: "3000"
    }
  };

constructor(
  private redirectService: ComponentRedirect
) { }

ngOnInit() {
  
  this.initSlider();
 

}

initSlider() {
  this.timer = parseInt(this.props.intervalTime.value) || this.defaultTime;
  this.currentSlide = this.data[0];
  this.createNewInterval();
}
nextSlide() {
  console.log("nextSlide");
  clearInterval(this.sliderInterval);
  this.currentSlideIndex = (this.currentSlideIndex + 1) % this.data.length;
  this.updateCurrentSlide()
  this.createNewInterval();
}

previousSlide() {
  clearInterval(this.sliderInterval);
  this.currentSlideIndex = (this.currentSlideIndex - 1 + this.data.length) % this.data.length;
  this.updateCurrentSlide()
  this.createNewInterval();
}

createNewInterval() {
  this.sliderInterval = setInterval(() => {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % this.data.length;
    this.currentSlide = this.data[this.currentSlideIndex];
  },this.timer);
}s

updateCurrentSlide() {
  this.currentSlide = this.data[this.currentSlideIndex];
  console.log(this.currentSlideIndex);
}

navigateItem(item) {
  this.redirectService.navigateItem(item)
}
}
