import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private url = environment.admin;

  constructor(private http: HttpClient) {

  }

  createByRegister(client){
    client.status = true;
    return this.http.post<any[]>(`${this.url}/client/createByRegister` ,client, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  checkIfEmailExist(email){
    const data = {email};

    return this.http.post<any[]>(`${this.url}/client/checkIfEmailExist` ,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  confirmAccount(token){
    const data = {token};

    return this.http.post<any[]>(`${this.url}/client/confirmAccount` ,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  sendConfirmationEmail(email){
    const data = {email};
    return this.http.post<any[]>(`${this.url}/client/sendConfirmationEmail`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  requestInstructions(email){
    const data = {email};
    return this.http.post<any[]>(`${this.url}/client/passwordRecovery`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  checkIfClientExist(token){
    const data = {token};
    return this.http.post<any[]>(`${this.url}/client/checkIfClientExistByToken`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  updateClientByToken(token, newPassword){
    const data = {token, newPassword};

    return this.http.post<any[]>(`${this.url}/client/newPasswordByToken`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }
};
