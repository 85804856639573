import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Footer, FooterMedia, FooterProps } from 'src/app/_interfaces/components.interface';
import { ComponentRedirect } from 'src/app/_services/componentRedirect.service';
import { StoreService } from 'src/app/_services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.page.html',
  styleUrls: ['./footer.page.scss'],
})
export class FooterPage implements OnInit {


  @Input() data =[];

  props : FooterProps ;
  logo : string;
  footer : Footer;
  payments :SafeResourceUrl[] = [];
  footerMedia : FooterMedia

  constructor(private redirectToPage: ComponentRedirect, private storeService: StoreService,private sanitizer: DomSanitizer) { 

  }

  ngOnInit() {
    this.storeService.getCommonConfigByEnterprise<Footer>(environment.enterpriseId,'footer').subscribe( (footer : Footer)=>{
      this.footer = footer;
      this.data= this.footer.data
      this.props=this.footer.props
      this.footerMedia = this.footer.media
      this.payments = this.footerMedia.payments.map( img => {
        let result = this.sanitizer.bypassSecurityTrustResourceUrl(img);
        return result
      })
    },err=>{

    })
  }

  navigateItem(item){
    this.redirectToPage.navigateItem(item)
  }

}
