
// replace-comma-component.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCommaComponent'
})
export class ReplaceCommaComponentPipe implements PipeTransform {
  transform(value: string): string {
    // Reemplazar comas por puntos
    return value.replace(/,/g, '.');
  }
}