import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'storefront',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'product-detail/:querys',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'storefront',
    loadChildren: () => import('./storefront/storefront.module').then( m => m.StorefrontPageModule)
  },
  {
    path: 'product-category/:querys',
    loadChildren: () => import('./product-category/product-category.module').then( m => m.ProductCategoryPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'card-grid',
    loadChildren: () => import('./components/card-grid/card-grid.module').then( m => m.CardGridPageModule)
  },
  {
    path: 'spinner',
    loadChildren: () => import('./components/spinner/spinner.module').then( m => m.SpinnerPageModule)
  },
  {
    path: 'card-list',
    loadChildren: () => import('./components/card-list/card-list.module').then( m => m.CardListPageModule)
  },
  {
    path: 'footer',
    loadChildren: () => import('./components/footer/footer.module').then( m => m.FooterPageModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./seller/seller.module').then( m => m.SellerPageModule)
  },
  {
    path: 'seller/:id',
    loadChildren: () => import('./seller/seller.module').then( m => m.SellerPageModule)
  },
  {
    path: 'simple-list',
    loadChildren: () => import('./components/simple-list/simple-list.module').then( m => m.SimpleListPageModule)
  },
  {
    path: 'store-list',
    loadChildren: () => import('./components/store-list/store-list.module').then( m => m.StoreListPageModule)
  },
  {
    path: 'user-menu',
    loadChildren: () => import('./components/user-menu/user-menu.module').then( m => m.UserMenuPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'client-profile',
    loadChildren: () => import('./client-profile/client-profile.module').then( m => m.ClientProfilePageModule)
  },
  {
    path: 'tracking',
    loadChildren: () => import('./tracking/tracking.module').then( m => m.TrackingPageModule)
  },
  {
    path: 'sellers',
    loadChildren: () => import('./sellers/sellers.module').then( m => m.SellersPageModule)
  },
  {
    path: 'store-tracking',
    loadChildren: () => import('./components/store-tracking/store-tracking.module').then( m => m.StoreTrackingPageModule)
  },
  {
    path: 'embebed',
    loadChildren: () => import('./embebed/embebed.module').then( m => m.EmbebedPageModule)
  },
  {
    path: 'register-successfully',
    loadChildren: () => import('./register-successfully/register-successfully.module').then( m => m.RegisterSuccessfullyPageModule)
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'confirm-email/:token',
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'how-buy',
    loadChildren: () => import('./how-buy/how-buy.module').then( m => m.HowBuyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
