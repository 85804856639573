import { AfterViewInit, Component } from '@angular/core';
import { CartService } from './_services/cart.service';
import { Router } from '@angular/router';
import { CategoryService } from './_services/category.service';
import { EventsService } from './_services/events.service';
import { DataStorageService } from './_services/dataStorage.service';
import { StoreService } from './_services/store.service';
import { environment } from 'src/environments/environment';

import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public appPages = [
    { title: 'Product', url: 'product-detail', icon: 'earth' }
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  cartVisibilityStatus = false;
  optionVisibilityStatus = false;
  isLogged = false;

  faviconUrl:string;
  tabTitle:string = 'Marketplace';

  categories = [{name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
  {name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
  {name:'hola', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
  {name:'chao', subCategories: [{name:'chao'}, {name: 'hola'}, {name:'pescao'}]},
  {name:'hola', subCategories: []},
  {name:'hola', subCategories: []}];

  
  constructor(private cartService: CartService,
     private dataStorage: DataStorageService,
      private eventsService: EventsService,
       private categoryService: CategoryService,
        private router: Router,
        private storeService: StoreService,
        private titleService:Title,
        private metaService: Meta) {

  }
  ngAfterViewInit(): void {

    const key = environment.gtag;
    const head = document.getElementsByTagName('head')[0];

    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "https://www.googletagmanager.com/gtag/js?id=G-"+key;
    document.body.appendChild(chatScript);
    
    const _js = document.createElement('script');
    _js.type = 'text/javascript';
    _js.appendChild(document.createTextNode("window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-"+key+"');"));
    head.appendChild(_js);

  }

  async ngOnInit(){    
       
    //set favicon
    this.storeService.getCommonConfigByEnterprise(environment.enterpriseId,'tab').subscribe(data=>{
      this.faviconUrl = data['props'].imgUrl;
      this.tabTitle = data['name'];

      this.titleService.setTitle(this.tabTitle);

      // this.metaService.addTag({ name: 'description', content: 'changing   tags' });
      // this.metaService.updateTag({ name: 'description', content: 'changing tags' });
      
      document.getElementById('appFavicon').setAttribute('href', this.faviconUrl);
  
    },err=>{
      this.titleService.setTitle("Default");
    })
   


    this.categoryService.listFullCategory().then((response) => {
      response.subscribe((categories) => {
        this.categories = categories;
        console.log(this.categories);
      });
    });

    const segment = this.cartService.getCartEmitter();

    segment.subscribe((status: any) => {
      this.cartVisibilityStatus = status;
    });

    this.eventsService.addEventEmitter('optionVisibility');
    this.eventsService.getEventEmitter('optionVisibility').subscribe(value =>{
      this.optionVisibilityStatus = value;
    })

    var val = await this.dataStorage.get('client');
    if(val){
      this.isLogged = true;
    }
    this.eventsService.addEventEmitter('isLogged');
    this.eventsService.getEventEmitter('isLogged').subscribe(value=>{
      this.isLogged = value;      
    });
  }

  navigateToProductCategory(category){
    this.router.navigate(['/product-category', 'c'],{queryParams: {category: category.alias}});
  }

  changeVisibilityStatus(){
    const value = !this.optionVisibilityStatus;
    this.eventsService.emitEventValue('optionVisibility', value);
  }

  navigateToSellers(){
    this.router.navigate(['sellers']);
  }

  navigateToProductCategorySub(subCategory){
    this.router.navigate(['/product-category', 'sc'],{queryParams: {subCategory: subCategory._id}});
  }
}
