import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface auth {
  accessToken : string;
  refreshToken : string;
  userId : string;
}

@Injectable()

export class AuthService {

  private url = environment.admin;

  constructor(private http: HttpClient) { }

  async logClient(client){
    return this.http.post<auth>(`${this.url}/auth/client`, client, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  async checkEmail(email){
    var form = {
      email:email
    }
    return this.http.post<auth>(`${this.url}/auth/emailExist/`, form, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }
}