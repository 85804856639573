import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



@Injectable({
    providedIn: 'root'
  })

export class ComponentRedirect {


  constructor( private router: Router) { }


  navigateItem(item){
    const {type} = item;
    console.log(item)

    const dictionary = {
      Categorias : this.navigateToCategory,
      Productos: this.navigateToProducts,
      Page: this.navigateToPage,
      Search: this.navigateToSearch,
    }

    const func = dictionary[item.type];
    
    func.call(this,{value:item.value});


  }

  navigateToCategory(data){
    const {value} = data;
    this.router.navigate(['/product-category', 'c'],{queryParams: {category: value}});
  }

  navigateToProducts(data){
    const {value} = data;
    this.router.navigate(['product-detail', 'p'], {queryParams: {cod: value}});
  }
  navigateToPage(data){
    console.log(data)
    window.location.href = data.value;
  }
  navigateToSearch(data){
    console.log("NAVIGATE DATA", data)
    const {value} = data;
    this.router.navigate(['/product-category', 's'],{queryParams: {search: value}});
  }

}