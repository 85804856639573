import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; //Protocolo http para que los servicios se comuniquen con la api

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CartService } from './_services/cart.service';
import { HigherCommonModule } from './higher-common.module';
import { UtilitiesService } from './_services/utilities.service';
import { IonicStorageModule } from '@ionic/storage-angular';

// captcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';

//services

import { ProductService } from './_services/product.service';
import { AuthService } from './_services/auth.service';
import { SessionService } from './_services/session.service';
import { DataStorageService } from './_services/dataStorage.service';
import { EventsService } from './_services/events.service';
import { ColorGeneratorService } from './_services/colorGenerator.service';
import { OrderService } from './_services/order.service';
import { ClientService } from './_services/client.service';
import { TrackingService }  from './_services/tracking.service';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
            IonicModule.forRoot(),
            AppRoutingModule,
            HttpClientModule,
            HigherCommonModule,
            IonicStorageModule.forRoot(),
            RecaptchaV3Module,
            FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
                CartService,
                UtilitiesService,
                ProductService,
                SessionService,
                AuthService,
                DataStorageService,
                EventsService,
                ColorGeneratorService,
                OrderService,
                ClientService,
                TrackingService,
                {
                  provide: RECAPTCHA_V3_SITE_KEY,
                  useValue: environment.captchaWebKey
                },
              ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
