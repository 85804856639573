import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from 'src/app/_services/utilities.service';
@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.page.html',
  styleUrls: ['./product-price.page.scss'],
})
export class ProductPricePage implements OnInit {
  @Input () price = 0;
  @Input () quantity = 1;
  @Input () currency = 'CLP';
  @Input() set stock(v : any) {
    console.log('v',v)
    this.currentStock = v
    this.rangeValue = this.valueInRange();    
  }
  currentStock : any;
  productDiscount = 0;
  rangeValue = 0;
  productPercentage = 0;
  constructor(private utilitiesService:UtilitiesService) { }

  ngOnInit() {
    //this.currentStock.originalPrice = this.currentStock.price;
    //console.log('stock',this.currentStock)
    //this.productDiscount = this.calculatePercentage(this.price, this.productPercentage);
  }

  calculatePercentage(data) {  
    const {price ,value} = data;
    // console.log('executed');
    this.productPercentage = value;
    if(this.productPercentage){      
      return price - Math.round((this.productPercentage / 100) * price);
    }    
    return price;
  }
  setValue(data){
    const {price ,value} = data;
    this.productPercentage = 100 - Math.round((value * 100 ) / price);
    return value;
  }
  numberWithPoints(number){
    return this.utilitiesService.numberWithPoints(number)
  }
  filterByRange(range){
    if(range.from <= this.quantity && range.to >= this.quantity){
      return true
    }
    return false
  }
  calculateResult(range){
    const price = this.currentStock.price;
    const map = {
      'discount':this.calculatePercentage,
      'value' : this.setValue
    }    
    const fun = map[range.type];
    return  fun.call(this,{price,value:range.value});
  }
  valueInRange(){
    if(this.currentStock && this.currentStock.ranges){      
      this.currentStock.price = this.currentStock.originalPrice || this.currentStock.price;
      const result = this.currentStock.ranges.filter(range => this.filterByRange(range))
      if(result.length > 0){
        return this.currentStock.price = this.calculateResult(result[0]);
      }
      this.productPercentage = 0;
      return this.currentStock.price;      
    }else{
      return this.currentStock.price;      
    }
  }
}
