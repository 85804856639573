import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-drop-info',
  templateUrl: './drop-info.page.html',
  styleUrls: ['./drop-info.page.scss'],
})
export class DropInfoPage implements OnInit {

  @Input () type = 'description';
  @Input () seen = false;
  @Input () dropTitle = 'Hola';
  @Input () dropText = 'chaooo';
  @Input () listText = ['hola','chao','pescao','como estay'];
  @Input () price = 0;
  @Input () ranges = [];

  constructor() { }

  ngOnInit() {
  }

  changeSeen(){
    this.seen = !this.seen;
  }

  calculateDiscount(price, discount){
    return price - discount;
  }
  calculateDiscountPercentage(price,discount){
    if (price === 0) {
      return;
    }
    const discountPercentage: number = 100 - Math.round( (discount * 100) / price );
    return discountPercentage;
  }
  calulateTo(value){
    if(value===999999999){
      return "Mas"
    }
    return value
  }
}
