import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/_services/events.service';
import { DataStorageService } from 'src/app/_services/dataStorage.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.page.html',
  styleUrls: ['./user-menu.page.scss'],
})

export class UserMenuPage implements OnInit {

  isLogged = false;
  
  guessOptions = [{name:'Ingresa', icon:'enter-outline', route:'login'},
             {name:'Regístrate', icon:'clipboard-outline', route:'register'}
  ];

  loggedOptions = [{name:'Mi Cuenta', icon:'enter-outline', route:'client-profile'}];

  constructor(private router: Router, private alertController: AlertController, private dataStorage: DataStorageService, private eventsService: EventsService) { }

  navigateToOptionRoute(optionRoute){
    this.emitOptionValue();
    this.router.navigate([optionRoute]);
  }

  async ngOnInit() {
    var val = await this.dataStorage.get('client');
    if(val){
      this.isLogged = true;
    }

    this.eventsService.getEventEmitter('isLogged').subscribe(value=>{
      this.isLogged = value;      
    });
  }

  // close menu after the user pick a choise 

  emitOptionValue(){
    this.eventsService.emitEventValue('optionVisibility', false);
  }

  logOut(){
    this.emitOptionValue();
    this.eventsService.emitEventValue('isLogged', false);
    this.dataStorage.clear();
    this.navigateToOptionRoute('storefront');
    this.logOutAlert();
  }

  async logOutAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      subHeader: 'Estado de Sesión',
      message: 'Sesión finalizada correctamente',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            
          }
        }
      ]
    });

    await alert.present();
  }
}
