import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProductGalleryPage } from './components/product-gallery/product-gallery.page';
import { DropInfoPage } from './components/drop-info/drop-info.page';
import { LinePage } from './components/line/line.page';
import { ProductPricePage } from './components/product-price/product-price.page';
import { GeneralBarPage } from './components/general-bar/general-bar.page';
import { MiniCartPage } from './components/mini-cart/mini-cart.page';
import { CardGridPage } from './components/card-grid/card-grid.page';
import { SpinnerPage } from './components/spinner/spinner.page';
import { CardListPage } from './components/card-list/card-list.page';
import { BannerPage } from './components/banner/banner.page';
import { FooterPage } from './components/footer/footer.page';
import { SimpleListPage } from './components/simple-list/simple-list.page';
import { StoreListPage } from './components/store-list/store-list.page';
import { UserMenuPage } from './components/user-menu/user-menu.page';
import { ColorGeneratorPage } from './components/color-generator/color-generator.page';
import { StoreTrackingPage } from './components/store-tracking/store-tracking.page';
import { SliderComponent } from './components/slider/slider.component';
import { ReplaceCommaComponentPipe } from './_pipes/replace-comma-component.pipe';
@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  // eslint-disable-next-line max-len
  declarations: [ReplaceCommaComponentPipe, ProductGalleryPage, DropInfoPage, StoreTrackingPage, ColorGeneratorPage, LinePage, ProductPricePage, GeneralBarPage, MiniCartPage, UserMenuPage, SpinnerPage, CardGridPage, CardListPage, BannerPage, FooterPage, SimpleListPage, StoreListPage,SliderComponent],
  // eslint-disable-next-line max-len
  exports: [ProductGalleryPage, DropInfoPage, StoreTrackingPage, ColorGeneratorPage, LinePage, ProductPricePage, GeneralBarPage, MiniCartPage, UserMenuPage, SpinnerPage, CardGridPage, CardListPage, BannerPage, FooterPage, SimpleListPage, StoreListPage,SliderComponent]
})
export class HigherCommonModule { }
