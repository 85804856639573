import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentRedirect } from 'src/app/_services/componentRedirect.service';
import { ProductService } from 'src/app/_services/product.service';
import { UtilitiesService } from 'src/app/_services/utilities.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.page.html',
  styleUrls: ['./card-list.page.scss'],
})
export class CardListPage implements OnInit {
  @Input() data=[];
  @Input() props = {} ;
  products = [];
  quantity = 1;
  descriptionTextMaxValue=110;
  titleTextMaxValue= 35;


  constructor(private router: Router, 
    private redirectToPage: ComponentRedirect,
    private productService : ProductService,
    private utilitiesService: UtilitiesService,
    private cdRef:ChangeDetectorRef) { }

  ngOnInit() {
    this.iterateAnFillProducts();
    console.log(this.products)
  }

  ngAfterViewChecked(){

  this.cdRef.detectChanges();
  }

  async iterateAnFillProducts(){
    const productsPromises = [];
    console.log(this.data)
    for(const item of this.data){
      const id = item.value;      
      productsPromises.push(this.bringProduct(id))
    }
    const promises = await Promise.all(productsPromises);
    this.products = promises;
    console.log(this.products)
  }
  async bringProduct(productId){
    return new Promise( (resolve,reject)=>{
      this.productService.getOne(productId).subscribe( data => {        
        resolve(data);
      })
    })
  }
  maxSizeString(text,size){
    if(text){
      return text.slice(0,size);
    }else{
      return '';
    }      
  }
  navigateItem(item){
    
    this.redirectToPage.navigateItem(item);

  }
  calculatePercentage(data) {  
    const {price ,value} = data;
    if(value){      
      return price - Math.round((value / 100) * price);
    }    
    return price;
  }
  setValue(data){
    const {value} = data;    
    return value;
  }
  calculateResult(range,price){    
    const map = {
      'discount':this.calculatePercentage,
      'value' : this.setValue
    }    
    const fun = map[range.type];
    return  fun.call(this,{price,value:range.value});
  }
  filterByRange(range){
    if(range.from <= this.quantity && range.to >= this.quantity){
      return true
    }
    return false
  }
  valueInRange(stock){
    if(!stock.originalPrice){
      stock.originalPrice = stock.price;
    }
    if(stock && stock.ranges && stock.ranges.length > 0){         
      stock.price = stock.originalPrice || stock.price;
        const firstRangePrice = stock.ranges[0]
      // stock.ranges.filter(range => this.filterByRange(range))
        return stock.price = this.calculateResult(firstRangePrice,stock.price);
    }else{
      return stock.price;      
    }
    
  }

}
