import { ChangeDetectorRef, Component, OnInit, AfterViewChecked } from '@angular/core';
import { CartService } from '../../_services/cart.service';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/_services/cookies.service';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/_services/utilities.service';
import { DataStorageService } from 'src/app/_services/dataStorage.service';
import { EventsService } from 'src/app/_services/events.service';
import { SessionService } from 'src/app/_services/session.service';
@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.page.html',
  styleUrls: ['./mini-cart.page.scss'],
})
export class MiniCartPage implements OnInit, AfterViewChecked {
  cartItems = [];
  cartStatus = false;
  loadingCheckout = false;

  isLogged = false;
  client;
  token: string = '';

  constructor(private cookieService : CookieService,
    private sessionService: SessionService,
    private eventsService: EventsService,
    private dataStorage: DataStorageService,
    private cartService: CartService,
    private router: Router,
    private utilitiesService:UtilitiesService,
    private changeDetector: ChangeDetectorRef) {}

  async ngOnInit() {
    const val = await this.dataStorage.get('client');
    if(val){
      this.client = val;
      this.isLogged = true;
      this.token = this.sessionService.getToken();
    }
  
    this.eventsService.getEventEmitter('isLogged').subscribe(value=>{
      this.isLogged = value;
      this.token = this.isLogged ? this.sessionService.getToken() : '';
    });

    this.cartStatus = this.cartService.getCartVisibility();
    this.cartItems = this.cartService.getCartArray();
    const productEmition = this.cartService.getCartProductEmitter();

    productEmition.subscribe((cartArray: any) => {
      this.cartItems = this.cartService.getCartArray();
      console.log('cartItems:', this.cartItems);
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }


  addQuantity(productIndex){
    const selectedQuantity = this.cartItems[productIndex].quantity;
    const maxQuantity = this.cartItems[productIndex].stock.quantity;
    if(maxQuantity > selectedQuantity){
      this.cartItems[productIndex].quantity += 1;
    }
    const product = this.cartItems[productIndex];
    
    this.updateProductToCart(product);
    this.reloadValue(this.cartItems[productIndex],productIndex)

  }  
  subtractQuantity(productIndex){
    const selectedQuantity = this.cartItems[productIndex].quantity;
    const minQuantity = 1;
    if(minQuantity < selectedQuantity){
      this.cartItems[productIndex].quantity -= 1;
    }
    const product = this.cartItems[productIndex];
    this.updateProductToCart(product);
    this.reloadValue(this.cartItems[productIndex],productIndex)
  }
  reloadValue(p,i){
    p.realPrice = this.numberWithPoints(this.valueInRange(p.stock,i));
  }
  calculateCartTotal(){
    let total = 0;
    let index = 0;
    for(const p of this.cartItems){      
      this.reloadValue(p,index)
      total += this.calculateTotal(p.quantity, p.stock.price);
      index++
    }
    return total;
  }

  updateProductToCart(product){
    this.cartService.updateQuantityProductToCart(product);
  }

  removeProductToCart(product){
    this.cartService.removeProductToCart(product);

  }

  closeCart(){
    this.cartService.emitCartStatus(false);
    this.dataStorage.set('currentCart',this.cartItems);
  }

  calculateTotal(quantity, price){
    return quantity * price;
  }

  numberWithPoints(number){
    return this.utilitiesService.numberWithPoints(number)
  }

  navigateToCart(){
    this.closeCart();
    this.router.navigate(['cart'], {replaceUrl: true});
  }
  goToCheckout() {
    this.loadingCheckout = true;
    const cartJson = JSON.stringify(this.cartService.cart);
    let uuid = this.cookieService.getUuid();
  
    if (!uuid) {
      uuid = this.cartService.generateUUID();
      document.cookie = `uuid${environment.marketKey}=${uuid}`;
    }
  
    this.cartService.setCart(uuid, { cart: this.cartService.cartToApi }).subscribe(result => {
      const tokenParam = this.isLogged ? `&token=${this.token}` : '';
  
      // Eliminar la cookie antes de redirigir
      document.cookie = `uuid${environment.marketKey}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  
      window.location.href = environment.checkout + "?cart=" + uuid + tokenParam;
  
      this.loadingCheckout = false;
    }, err => {
      this.loadingCheckout = false;
    });
  
    localStorage.setItem('cart' + environment.marketKey, cartJson);
  }
  filterByRange(range,quantity){
    
    if(range.from <= quantity && range.to >= quantity){
      return true
    }
    return false
  }
  calculatePercentage(data) {  
    const {price ,value} = data;    
    if(value){      
      return price - Math.round(( value / 100) * price);
    }    
    return price;
  }
  setValue(data){
    const {value} = data;    
    return value;
  }
  calculateResult(stock,range){
    const price = stock.price;
    const map = {
      'discount':this.calculatePercentage,
      'value' : this.setValue
    }    
    const fun = map[range.type];    
    return  fun.call(this,{price,value:range.value});
  }
  valueInRange(stock,index){
    const quantity = this.cartItems[index].quantity
    stock.price = stock.originalPrice || stock.price;
    stock.discount = 0;
    if(stock && stock.ranges){      
      const result = stock.ranges.filter(range => this.filterByRange(range,quantity))
      if(result.length > 0){
        stock.price = this.calculateResult(stock,result[0]);
        stock.discount = 100 - Math.round( (stock.price * 100) /stock.originalPrice )

      }      
    }
    return stock.price;
  }
  

}
