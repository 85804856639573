
import { Injectable } from '@angular/core';

@Injectable()

export class TrackingService {
  tracking = {status: false, trackingCode: ''};

  constructor() { }

  setCode(code){
    this.tracking.trackingCode = code;
    this.tracking.status = true;
  }

  getCode(){
    return this.tracking.trackingCode;
  }

  cleanCode(){
    this.tracking.trackingCode = '';
    this.tracking.status = false;
  }

}