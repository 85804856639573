import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataStorageService } from './dataStorage.service';

@Injectable()

export class CartService {
    cart = {};
    cartArray = [];
    cartToApi = {};
    cartVisibilityStatus = false;
    private cartEmmiter = new EventEmitter();
    private cartProductsEmitter = new EventEmitter();    
    constructor(private dataStorage : DataStorageService,private http: HttpClient) {
        this.init()
        
    }

    async init(){
        const cartArray = await this.dataStorage.get('currentCart');
    
        if(cartArray){   
            for(let i = 0; i< cartArray.length; i++){
                const productInfo = cartArray[i]; 
                this.addProductToCart(productInfo.product, productInfo.stock, productInfo.quantity);
            }
        }

        this.emitCartArray(false)
    }
    generateUUID() : string {
        let
          d = new Date().getTime(),
          d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
          let r = Math.random() * 16;
          if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
    }
    simpleUuid() : string {
        return  `${new Date().getMilliseconds()}`;
      }
      
    /* status */
    setCartVisibility(status) {
        this.cartVisibilityStatus = status;
        return this.cartVisibilityStatus;
    }

    getCartVisibility(){
        return this.cartVisibilityStatus;
    }

    emitCartStatus(status){        
        this.setCartVisibility(status);
        this.cartEmmiter.emit(status);
    }

    getCartEmitter(){
        return this.cartEmmiter;
    }

    /* products */
    getCartProductEmitter(){
        return this.cartProductsEmitter;
    }

    emitCartArray(showCart: boolean){
        this.dataStorage.set('currentCart',this.getCartArray());
        this.cartProductsEmitter.emit('cartArray');
        this.emitCartStatus(showCart);
    }

    getCartArray(){
        const tempCart = [];
        console.log('this cart',this.cart)
        // eslint-disable-next-line guard-for-in
        for(const sellerId in this.cart){
            const products = this.cart[sellerId].products;
            console.log('products', products);
            // eslint-disable-next-line guard-for-in
            for(const product in products){
                const productObject = products[product].product;
                const stock = products[product].stock;
                const media = products[product].product.media;  
                const mediaMap = {};
                for(const tempMedia of media){
                    mediaMap[tempMedia.id] = tempMedia.img.url;
                }
                const index = ( Array.isArray(stock?.media) ? stock.media[0] : 0)
                stock.img = mediaMap[index] || productObject?.originalMedia[0].img.url;
                products[product].product = productObject;
                tempCart.push(products[product]);
            }
        }
        return tempCart;
    }
    setCart(uuid,payload){
        uuid = uuid.trim();
        const url = environment.cartApi+"/cart?uuid=";        
        return this.http.post<any[]>(`${url}${uuid}` ,payload, {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
          });
    }

    addProductToCart(product, stock, quantity){                
        if(!this.cartToApi[product.sellerId]){
            this.cartToApi[product.sellerId] = {};
            this.cartToApi[product.sellerId].products = {};
        }
        const tempProduct = {
            name : product.title,
            id : product.id,
            height : stock.height || 15,
            width : stock.width || 15,
            long : stock.long || 15,
            quantity : quantity,
            stockCode : stock.code
        }        
        this.cartToApi[product.sellerId].products[stock.code] = tempProduct;
        console.log('cartToApi',this.cartToApi)
        /**/ 
        console.log('product:', product);
        console.log('stock:', stock);
        console.log('quantity:', quantity);

        const stockCode = stock.code;
        const sellerId = product.sellerId;

        if(!this.cart[sellerId]){
            this.cart[sellerId] = { products:{ [stockCode] : {product, stock, quantity}}};
            this.emitCartArray(true);
            // console.log(this.cart, this.cartArray);  
            return true;     
        }

        if(!this.cart[sellerId].products[stockCode]){
            // console.log(this.cart, this.cartArray);
            this.cart[sellerId].products[stockCode] = {product, stock, quantity};
        }else{
            const maxQuantity = this.cart[sellerId].products[stockCode].stock.quantity;
            this.cart[sellerId].products[stockCode].quantity += quantity;
            if(maxQuantity < this.cart[sellerId].products[stockCode].quantity){
                this.cart[sellerId].products[stockCode].quantity = maxQuantity;
            }
        }
        this.emitCartArray(true);
    }

    async removeProductToCart(element){
        const stockCode = element.stock.code;
        const sellerId = element.product.sellerId;
        const products = this.cart[sellerId].products;
        const cartToApiProducts = this.cartToApi[sellerId].products;
        delete products[stockCode];
        delete cartToApiProducts[stockCode];


        if(Object.keys(products).length === 0){
            delete this.cart[sellerId];
            delete this.cartToApi[sellerId];

            this.dataStorage.remove('currentCart')

        } else{
            this.dataStorage.set('currentCart', this.getCartArray())
        }
        
        this.emitCartArray(true);
    }

    updateQuantityProductToCart(element){
        const stockCode = element.stock.code;
        const sellerId = element.product.sellerId;
        const products = this.cart[sellerId].products;
        
        const cartToApiProducts = this.cartToApi[sellerId].products;
        const cartToApiProduct = cartToApiProducts[stockCode];
        cartToApiProduct.quantity = element.quantity;
        this.emitCartArray(true);
    }
    
};
