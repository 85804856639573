import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface Range {
    from: number;
    to: number;
    type: 'value' | 'discount';
    value: number;
}
@Injectable()

export class UtilitiesService {
    canBeUsed: boolean = true;
    numberWithPoints(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    async canActivate(time: number) {

        return new Promise(async (resolve, reject) => {
            if (this.canBeUsed) {
                resolve(true)
                setTimeout(this.reactivate, time)
                this.canBeUsed = false
                return
            }
            reject('isBussy')
            return;
        })
    }
    reactivate() {
        this.canBeUsed = true;
    }

    calculateProductTotal(ranges: Range[], quantity: number, originalPrice: number): number {
        let discount = 0;

        if(!ranges){
            return discount
        }

        for (const range of ranges) {
            if (quantity >= range.from && quantity <= range.to) {
                if (range.type === 'value') {
                    discount = quantity * range.value;
                } else if (range.type === 'discount') {
                    discount =  quantity * (originalPrice * (range.value / 100));
                }
                break;
            }
        }

        return discount;
    }
}