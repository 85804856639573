
import { Injectable } from '@angular/core';

@Injectable()

export class ColorGeneratorService {

  constructor() { }

  generateColor(firstLetter: string, secondLetter: string){
    const a = firstLetter.charCodeAt(0);
    const b = secondLetter.charCodeAt(0);
    const c = Math.abs(255 - (Math.abs(a -b)));

    return this.getHexCodeByOrder(a,b,c)
  }

  getHexCodeByOrder(a,b,c) {
    if(this.isOdd(a) && this.isOdd(b)){
        return this.rgbToHex(c,a,b);
    }

    if(this.isOdd(a) != this.isOdd(b)){
      return this.rgbToHex(a, c - 10, b);
    }

    if(!this.isOdd(a) == !this.isOdd(b)){
      return this.rgbToHex(a,b, c);
    }
  };

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  isOdd(num) { return num % 2 };
}