import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private url = environment.admin;

  constructor(private http: HttpClient) {
  }

   list() {
    return this.http.get<any[]>(`${this.url}/order/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  listOrderByTrackingCode(trackingCode){
    return this.http.get<any[]>(`${this.url}/order/listByTrackingCode` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('trackingCode', trackingCode)
    });
  }

  listOrderByBuyer(buyerId){
    return this.http.get<any[]>(`${this.url}/order/listByBuyer` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('buyerId', `${buyerId}`)
    });
  }

  getOne(code){
    console.log('aqui igual llegué');
    return this.http.get<any[]>(`${this.url}/order/${code}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  getOneByCode(code){
    return this.http.get<any[]>(`${this.url}/order/getByCode` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('ordercode', code)
    });
  }
};
