/* eslint-disable @typescript-eslint/no-empty-interface */
import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/_services/product.service';

interface Media extends Array<MediaItem>{}

interface MediaItem {
  name: string;
  img: {
    url: string;
    name: string;
    storage: string;
    fileData: object;
  };
}

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.page.html',
  styleUrls: ['./product-gallery.page.scss'],
})
export class ProductGalleryPage implements OnInit {
  @Input() galleryElements: Media = [];

  slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  selectedGalleryElement = {url:''};

  currentIndex = 0;

  constructor(private productService : ProductService) { }

  ngOnInit() {
    this.startGallery();
    const restart = this.productService.getMediaRestartEvent();
    restart.subscribe( ()=>{
      this.selectElement(0);
    })
  }

  startGallery(){
    console.log('this.galleryElements:', this.galleryElements);
    if(this.galleryElements.length > 5){
      this.galleryElements = this.galleryElements.slice(0,5);
    }

    if(this.galleryElements.length === 0){
      this.galleryElements.push(
        {name:'no se ha encontrado una imagen',
         img:{
           // eslint-disable-next-line max-len
           url:'https://cdn.dribbble.com/users/429576/screenshots/8231332/media/e9dc9e1420a1dc6c0966fa5cce3b2cb4.jpg?compress=1&resize=1200x900&vertical=top',
           name:'',
           storage:'',
           fileData:{}
          }
        });
    }
    this.selectElement(0);
    this.selectedGalleryElement.url = this.galleryElements[0].img.url;
  }

  selectNextElement(index){
    let nextIndex = index + 1;
    if(this.galleryElements.length === nextIndex){
      nextIndex = 0;
    }
    this.changeGalleryImage(nextIndex);
    this.currentIndex = nextIndex;
  }

  selectElement(index){
    console.log('selected',index)
    this.changeGalleryImage(index);
    this.currentIndex = index;
  }

  changeGalleryImage(index){
    this.selectedGalleryElement.url = this.galleryElements[index]?.['img']?.url;
  }

  ngOnDestroy(){
    this.galleryElements = [];
    this.slideOpts = {
      initialSlide: 1,
      speed: 400
    };
  
    this.selectedGalleryElement = {url:''};
  
    this.currentIndex = 0;
  }
}
