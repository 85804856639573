import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class EventsService {

    events = {};

    constructor(){
    }

    addEventEmitter(key){
        this.events[key] = new EventEmitter();
    }

    emitEventValue(key, value){
        this.events[key].emit(value)
    }

    getEventEmitter(key){
        return this.events[key];
    }
}